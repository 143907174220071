input[type="text"], select {
    clear: both;
    display: block;
    margin-top: 1em;
}

label {
    display: block;
    margin-bottom: 1em;
    clear: both;
}

tr.redirect:hover td {
    border-bottom: 1px solid #000;
}
tr.redirect:nth-child(odd) {
    background: #e8e8e8;
}

footer {
    margin-top: 2em;
}